import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthFeatureHubspotChatService } from '@enx/auth/feature/hubspot';
import { SharedFeatureCookieConsentService, SharedFeatureIconsService } from '@enx/shared/feature/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'enx-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private readonly iconsService = inject(SharedFeatureIconsService);
  private readonly cookieConsentService = inject(SharedFeatureCookieConsentService);
  private readonly hubspotChatService = inject(AuthFeatureHubspotChatService);

  constructor() {
    this.iconsService.registerIcons();
  }

  ngOnInit(): void {
    this.checkConsent();
  }

  private checkConsent(): void {
    this.cookieConsentService.requestStorageAccess().pipe(untilDestroyed(this)).subscribe();
  }
}
