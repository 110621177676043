import { Environment } from './environment.model';

export const environment: Environment = {
  apiUrl: 'https://enerix-webapp-dev.lab.inspeerity.com/api/',
  appleLogin:
    'https://enerix-dev.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=https://enerix-webapp-dev.lab.inspeerity.com/login&response_type=CODE&client_id=4n3sj7oqfo8513biqguer731u7&scope=aws.cognito.signin.user.admin%20openid',
  googleLogin:
    'https://enerix-dev.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://enerix-webapp-dev.lab.inspeerity.com/login&response_type=CODE&client_id=4n3sj7oqfo8513biqguer731u7&scope=aws.cognito.signin.user.admin%20openid',
  hubspot: {
    portalId: '144047131',
  },
};
