import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedFeatureLoaderService } from '@enx/shared/feature/services';
import { Observable, Subscription, timer } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class SharedFeatureLoaderInterceptor implements HttpInterceptor {
  private readonly THRESHOLD = 200;

  constructor(private loaderService: SharedFeatureLoaderService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.url.includes('/api') || req.url.includes('/api/notifications?page')) {
      return next.handle(req);
    }

    let loaderTimeout: Subscription | null = null;

    const request$ = next.handle(req).pipe(
      finalize(() => {
        if (loaderTimeout) {
          loaderTimeout.unsubscribe();
        }
        this.loaderService.hide(req.url);
      }),
    );

    loaderTimeout = timer(this.THRESHOLD).subscribe(() => {
      this.loaderService.show(req.url);
    });

    return request$;
  }
}
