import { DestroyRef, Injectable, InjectionToken, Provider, computed, effect, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LocalStorage } from '@enx/shared/util/enums';
import { SharedFeatureConsentStateService } from '@enx/shared/feature/services';
import { AuthDataAccessHubspotChatService } from '@enx/auth/data-access';

export const HUBSPOT_PORTAL_ID = new InjectionToken<string>('HUBSPOT_PORTAL_ID');
export function provideHubspotPortalId(portalId: string): Provider {
  return { provide: HUBSPOT_PORTAL_ID, useValue: portalId };
}

@Injectable({
  providedIn: 'root',
})
export class AuthFeatureHubspotChatService {
  private readonly document = inject(DOCUMENT);
  private readonly hubspotAuth = inject(AuthDataAccessHubspotChatService);
  private readonly consentState = inject(SharedFeatureConsentStateService);
  private readonly PORTAL_ID = inject(HUBSPOT_PORTAL_ID);

  private privacyConsentSet = false;
  private isEnabled = false;

  constructor() {
    const canEnableHubSpot = computed(() => {
      return this.consentState.consentState() && !this.isEnabled;
    });

    effect(() => {
      if (canEnableHubSpot()) {
        this.enableHubSpot();
      }
    });
  }

  private enableHubSpot(): void {
    if (!this.isEnabled) {
      const userEmail = localStorage.getItem(LocalStorage.USER_EMAIL);
      const accessToken = localStorage.getItem(LocalStorage.AUTH_TOKEN);

      this.isEnabled = true;

      if (userEmail && accessToken) {
        const hubspotToken = localStorage.getItem(LocalStorage.HUBSPOT_TOKEN);
        const tokenExpiration = localStorage.getItem(LocalStorage.HUBSPOT_TOKEN_EXPIRATION);
        const isTokenExpired = !tokenExpiration || new Date(tokenExpiration) <= new Date();

        if (!hubspotToken || isTokenExpired) {
          this.hubspotAuth.authenticateHubSpotUser().subscribe({
            next: ({ token }) => {
              const expirationDate = new Date();
              expirationDate.setHours(expirationDate.getHours() + 11);
              localStorage.setItem(LocalStorage.HUBSPOT_TOKEN, token);
              localStorage.setItem(LocalStorage.HUBSPOT_TOKEN_EXPIRATION, expirationDate.toISOString());
              this.initializeHubSpot(userEmail, token);
              this.loadHubSpotScript();
            },
          });
        } else {
          this.initializeHubSpot(userEmail, hubspotToken);
          this.loadHubSpotScript();
        }
      } else {
        this.loadHubSpotScript();
      }
    }
  }

  private initializeHubSpot(email: string, token: string): void {
    if (typeof window !== 'undefined') {
      window._hsq = window._hsq || [];

      if (!this.privacyConsentSet) {
        window._hsq.push(['identify', { email }]);
        window._hsq.push(['setPrivacyConsent', true]);
        window._hsq.push(['doNotTrack', false]);
        this.privacyConsentSet = true;
      }

      window.hsConversationsSettings = {
        identificationEmail: email,
        identificationToken: token,
      };
    }
  }

  private loadHubSpotScript(): void {
    const existingScript = this.document.getElementById('hubspot-script');
    if (existingScript) {
      existingScript.remove();
    }
    const script = this.document.createElement('script');
    script.id = 'hubspot-script';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = `https://js-eu1.hs-scripts.com/${this.PORTAL_ID}.js`;

    this.document.head.appendChild(script);
  }
}
