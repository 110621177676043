import { Route } from '@angular/router';
import { ROUTE } from '@enx/shared/util/enums';

export const APP_ROUTES: Route[] = [
  {
    path: ROUTE.APP,
    loadChildren: () => import('@enx/sidebar-layout').then((m) => m.SIDEBAR_LAYOUT_ROUTES),
  },
  {
    path: '',
    loadChildren: () => import('@enx/footer-layout').then((m) => m.FOOTER_LAYOUT_ROUTES),
  },
];
