import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedFeatureIconsService } from '@enx/shared/feature/services';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'enx-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private readonly iconsService = inject(SharedFeatureIconsService);

  constructor() {
    this.iconsService.registerIcons();
  }
}
